import { createApp } from 'vue'
import App from './App.vue'
import './assets/styles.css'
import router from './router'
import VueGtag from 'vue-gtag';

const app = createApp(App);

// Use the router
app.use(router);

// Use VueGtag
app.use(VueGtag, {
    config: { id: "G-RKGD5XQ9XP" }
});

// Mount the app
app.mount("#app");