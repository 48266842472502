<template>
    <div class="mx-auto">
        <!-- HERO SECTION -->
        <section class="bg-gradient-to-r from-blue-500 to-blue-700 text-white py-16 px-4">
            <div class="container mx-auto flex flex-col md:flex-row items-center">
                <div class="md:w-1/2 mb-8 md:mb-0">
                    <h1 class="text-4xl md:text-5xl font-bold mb-4 leading-tight">
                        SKYROCKET YOUR BUSINESS EFFICIENCY
                    </h1>
                    <p class="text-xl mb-8">
                        Our courses show you exactly how to implement AI to boost productivity, even if you're not a
                        tech expert.
                    </p>
                    <button
                        class="bg-white text-blue-600 font-bold py-3 px-8 rounded-full shadow-lg hover:bg-blue-100 transition duration-300">
                        START MY FREE TRIAL
                    </button>
                </div>
                <div class="md:w-1/2">
                    <img class="w-full rounded-lg shadow-lg" src="../assets/hero.png"
                        alt="Smiling small business owner">
                </div>
            </div>
        </section>

        <!-- BENEFITS SECTION -->
        <section class="py-16 bg-gray-100">
            <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div class="text-center">
                    <h2 class="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                        Unlock the Power of AI for Your Business
                    </h2>
                    <p class="mt-4 text-lg text-gray-600">
                        Learn how artificial intelligence can revolutionize the way you work and serve your customers.
                    </p>
                </div>

                <div class="mt-10 space-y-10 md:space-y-0 md:grid md:grid-cols-3 md:gap-x-8">
                    <!-- Benefit 1 -->
                    <div class="bg-white p-6 shadow rounded-lg">
                        <div class="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                            </svg>
                        </div>
                        <h3 class="mt-4 text-xl font-bold text-gray-900">Save Time</h3>
                        <ul class="mt-4 list-disc pl-5 space-y-2 text-gray-600">
                            <li>Learn how AI can handle data entry, invoicing, scheduling and more</li>
                            <li>Reclaim hours each week to focus on growth and serving customers</li>
                        </ul>
                    </div>

                    <!-- Benefit 2 -->
                    <div class="bg-white p-6 shadow rounded-lg">
                        <div class="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                            </svg>
                        </div>
                        <h3 class="mt-4 text-xl font-bold text-gray-900">Serve More Customers</h3>
                        <ul class="mt-4 list-disc pl-5 space-y-2 text-gray-600">
                            <li>Discover how AI chatbots can provide 24/7 customer service</li>
                            <li>Free up your team to focus on higher-value interactions and tasks</li>
                        </ul>
                    </div>

                    <!-- Benefit 3 -->
                    <div class="bg-white p-6 shadow rounded-lg">
                        <div class="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                            </svg>
                        </div>
                        <h3 class="mt-4 text-xl font-bold text-gray-900">Make Smarter Decisions</h3>
                        <ul class="mt-4 list-disc pl-5 space-y-2 text-gray-600">
                            <li>Get AI-powered reports and predictions to optimize your strategies</li>
                            <li>Spot opportunities and risks faster for a competitive advantage</li>
                        </ul>
                    </div>
                </div>

                <div class="mt-12 text-center">
                    <a href="#"
                        class="inline-block bg-blue-600 border border-transparent rounded-full py-3 px-8 font-medium text-white hover:bg-blue-700">
                        See Course Outline
                    </a>
                </div>
            </div>
        </section>

        <!-- HOW IT WORKS SECTION -->
        <section class="py-12 bg-gray-100">
            <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div class="text-center">
                    <h2 class="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                        How It Works
                    </h2>
                </div>

                <div class="mt-10">
                    <ol class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                        <li class="flex flex-col items-center">
                            <div
                                class="flex items-center justify-center w-16 h-16 rounded-full bg-blue-500 text-white text-2xl font-bold">
                                1
                            </div>
                            <div class="mt-4 text-lg font-medium text-gray-900">
                                SUBSCRIBE risk-free with our 7-day free trial
                            </div>
                        </li>
                        <li class="flex flex-col items-center">
                            <div
                                class="flex items-center justify-center w-16 h-16 rounded-full bg-blue-500 text-white text-2xl font-bold">
                                2
                            </div>
                            <div class="mt-4 text-lg font-medium text-gray-900">
                                LEARN at your own pace with bite-sized video lessons
                            </div>
                        </li>
                        <li class="flex flex-col items-center">
                            <div
                                class="flex items-center justify-center w-16 h-16 rounded-full bg-blue-500 text-white text-2xl font-bold">
                                3
                            </div>
                            <div class="mt-4 text-lg font-medium text-gray-900">
                                IMPLEMENT with our done-for-you AI templates and tools
                            </div>
                        </li>
                    </ol>
                </div>
                <div class="mt-12 text-center">
                    <button
                        class="bg-blue-600 text-white font-bold py-3 px-8 rounded-full hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50">
                        START MY FREE TRIAL
                    </button>
                </div>
            </div>
        </section>

        <!-- SOCIAL PROOF SECTION -->
        <section class="bg-gray-200 py-12">
    <div class="container mx-auto px-4">
        <div class="mb-8">
            <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
                <blockquote class="bg-white p-6 rounded-lg shadow-md flex">
                    <img src="../assets/photo/jane.png" alt="Jane D." class="w-16 h-16 rounded-full mr-4">
                    <div>
                        <p class="text-lg font-semibold mb-2">"This course helped me automate invoicing, saving over 10 hours a month!"</p>
                        <p class="text-gray-600">- Jane D., Boutique Owner</p>
                    </div>
                </blockquote>
                <blockquote class="bg-white p-6 rounded-lg shadow-md flex">
                    <img src="../assets/photo/mike.png" alt="Mike R." class="w-16 h-16 rounded-full mr-4">
                    <div>
                        <p class="text-lg font-semibold mb-2">"Using an AI chatbot lets me serve 25% more customers with the same staff."</p>
                        <p class="text-gray-600">- Mike R., Landscaping Co.</p>
                    </div>
                </blockquote>
                <blockquote class="bg-white p-6 rounded-lg shadow-md flex">
                    <img src="../assets/photo/wendy.png" alt="Wendy S." class="w-16 h-16 rounded-full mr-4">
                    <div>
                        <p class="text-lg font-semibold mb-2">"The AI forecasting tools have made our inventory planning 32% more efficient."</p>
                        <p class="text-gray-600">- Wendy S., Ecommerce Brand</p>
                    </div>
                </blockquote>
            </div>
        </div>
        <div class="mb-8">
            <div class="flex justify-center items-center space-x-8">
                <p class="text-lg font-semibold">4.6/5 average rating from 200+ reviews</p>
                <p class="text-lg font-semibold">Over 5,000 small businesses optimized</p>
                <p class="text-lg font-semibold">Featured in Small Biz Magazine</p>
            </div>
        </div>
        <div class="text-center">
            <button class="bg-blue-600 text-white font-bold py-3 px-8 rounded-full hover:bg-blue-700 transition duration-200">
                JOIN 5,000 SMALL BUSINESSES USING AI
            </button>
        </div>
    </div>
</section>

        <!-- COURSE OVERVIEW SECTION -->
        <section class="py-12 bg-gray-100">

            <div class="container mx-auto px-4">
                <h2 class="text-4xl font-bold mb-4 pb-8 border-b border-gray-200 text-center">COURSE OVERVIEW</h2>

                <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
                    <!-- Course Detail 1 -->
                    <div class="bg-white border border-gray-200 p-6 shadow-md">
                        <h3 class="text-2xl font-bold mb-4">Automate Admin Tasks with AI</h3>
                        <ul class="list-none">
                            <li class="mb-2 pl-6 relative">
                                <span
                                    class="absolute left-0 top-0 inline-block w-2 h-2 bg-blue-600 rounded-full mt-2"></span>
                                Set up AI tools to handle data entry, scheduling, invoicing and more
                            </li>
                            <li class="mb-2 pl-6 relative">
                                <span
                                    class="absolute left-0 top-0 inline-block w-2 h-2 bg-blue-600 rounded-full mt-2"></span>
                                Save hours each week on tedious admin work
                            </li>
                        </ul>
                    </div>
                    <!-- Course Detail 2 -->
                    <div class="bg-white border border-gray-200 p-6 shadow-md">
                        <h3 class="text-2xl font-bold mb-4">Provide 24/7 Service with AI Chatbots</h3>
                        <ul class="list-none">
                            <li class="mb-2 pl-6 relative">
                                <span
                                    class="absolute left-0 top-0 inline-block w-2 h-2 bg-blue-600 rounded-full mt-2"></span>
                                Deploy an AI chatbot without coding
                            </li>
                            <li class="mb-2 pl-6 relative">
                                <span
                                    class="absolute left-0 top-0 inline-block w-2 h-2 bg-blue-600 rounded-full mt-2"></span>
                                Provide instant answers and support any time
                            </li>
                        </ul>
                    </div>
                    <!-- Course Detail 3 -->
                    <div class="bg-white border border-gray-200 p-6 shadow-md">
                        <h3 class="text-2xl font-bold mb-4">Optimize Operations with AI Insights</h3>
                        <ul class="list-none">
                            <li class="mb-2 pl-6 relative">
                                <span
                                    class="absolute left-0 top-0 inline-block w-2 h-2 bg-blue-600 rounded-full mt-2"></span>
                                Generate AI-powered analytics to inform decisions
                            </li>
                            <li class="mb-2 pl-6 relative">
                                <span
                                    class="absolute left-0 top-0 inline-block w-2 h-2 bg-blue-600 rounded-full mt-2"></span>
                                Forecast sales, costs, demand and more
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="text-center">
                    <button
                        class="bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-full text-lg my-8">
                        EXPLORE THE FULL CURRICULUM
                    </button>

                </div>

            </div>
        </section>

        <!-- PRICING & SIGN UP SECTION -->
        <section class="py-16 bg-blue-50">
            <div class="mx-auto max-w-2xl">
                <h2 class="text-4xl font-bold mb-4 pb-8 border-b border-gray-200 text-center">PRICING & SIGN UP</h2>
                <p>Flexible plans for every small business:</p>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4 text-center">
                    <div class="p-6 bg-white border border-gray-300 rounded shadow-md">
                        <h3 class="text-xl font-bold mb-4">MONTHLY</h3>
                        <p><span class="text-3xl font-bold text-blue-600">$19</span>/month</p>
                    </div>
                    <div class="p-6 bg-white border border-gray-300 rounded shadow-md">
                        <h3 class="text-xl font-bold mb-4">ANNUAL</h3>
                        <p><span class="text-3xl font-bold text-blue-600">$190</span>/year (save 17%)</p>
                    </div>
                </div>
            </div>
            <ul class="list-disc pl-5 text-left mx-auto max-w-md mt-4">
                <li>Access to 25+ AI courses for business</li>
                <li>Downloadable guides and templates</li>
                <li>New courses added monthly</li>
                <li>24/7 community support</li>
                <li>30-day 100% money-back guarantee</li>
            </ul>
            <div class="mt-6 text-center">
                <button
                    class="bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-8 rounded-full transition duration-200">
                    ACTIVATE MY MEMBERSHIP
                </button>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'LandingPage'
}
</script>