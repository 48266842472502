<template>
    <div class="about">
        <h1>About Us</h1>
        <p>
            We are a team of passionate developers who are committed to delivering the best software solutions to our
            clients. Our mission is to help you optimize your work and make your life easier.
        </p>
        <h3>Our Team</h3>
        <ul>
            <li>John Doe - CEO</li>
            <li>Jane Doe - CTO</li>
            <li>Jim Doe - Lead Developer</li>
        </ul>
    </div>
</template>