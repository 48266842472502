<template>
    <footer class="bg-gradient-to-r from-gray-100 to-gray-200 py-12 px-4">
        <div class="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-4 gap-8">
            <div>
                <span class="emoji" role="img" aria-label="Logo">⚙️</span>
                <p class="text-gray-700 py-2">© 2024 Optimize Your Work</p>
            </div>
            <div>
                <h3 class="text-lg mb-4">Navigate</h3>
                <ul class="space-y-2">
                    <li><a href="/" class="text-gray-600 hover:text-blue-500">Home</a></li>
                    <li><a href="/about" class="text-gray-600 hover:text-blue-500">About</a></li>
                    <li><a href="/pricing" class="text-gray-600 hover:text-blue-500">Pricing</a></li>
                </ul>
            </div>
            <div>
                <h3 class="text-lg mb-4">Resources</h3>
                <ul class="space-y-2">
                    <li><a href="/blog" class="text-gray-600 hover:text-blue-500">Blog</a></li>
                    <li><a href="/guides" class="text-gray-600 hover:text-blue-500">Guides</a></li>
                    <li><a href="/tools" class="text-gray-600 hover:text-blue-500">Tools</a></li>
                </ul>
            </div>
            <div>
                <h3 class="text-lg mb-4">Contact Us</h3>
                <p class="text-gray-600">4 Shawsheen Rd<br>Andover MA 01810</p>
                <p class="text-gray-600 mt-2"><a href="mailto:hello@oprimizeyouwork.ai">hello@oprimizeyouwork.ai</a></p>
                <div class="flex space-x-4 mt-4"> <a href="#"><i
                            class="fab fa-twitter text-gray-600 hover:text-blue-400"></i></a> <a href="#"><i
                            class="fab fa-facebook text-gray-600 hover:text-blue-600"></i></a> <a href="#"><i
                            class="fab fa-instagram text-gray-600 hover:text-pink-500"></i></a> </div>
            </div>
        </div>
        <hr class="border-gray-300 my-8">
        <div class="text-sm text-gray-600 flex justify-center"> <a href="/privacy" class="mr-4">Privacy Policy</a> <a href="/terms">Terms &
            Conditions</a> </div>
    </footer>
</template>